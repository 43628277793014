import { Injectable, Inject } from '@angular/core';
import { PerguntaPerfilInvestimento } from '../../models/sinqia-api/pergunta-perfil-investimento';
import { EPerfilInvestimento, ETokenApi } from '../../enums';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { HttpClientService } from '../http-client.service';
import { ResponseApiCdModel } from '../../models';

@Injectable()

export class PerfilInvestimentoService extends BaseService {

    constructor(protected httpClient: HttpClient, @Inject('environment') protected environment, private httpClientService: HttpClientService) {
        super(httpClient, environment);
    }

    calcularResultadoQuestionario(perguntasRespondidas: Array<PerguntaPerfilInvestimento>) {
        let total = 0;
        perguntasRespondidas.forEach(pergunta => {
            let respostaSelecionada = pergunta.respostas.find(resp => resp.selecionada);
            if (respostaSelecionada)
                total += respostaSelecionada.valor;
        });
        return total;
    }

    GerarDescricaoPerfilInvestimento(perfil: EPerfilInvestimento) {
        let resultPerfilText = 'PERFIL ';
        if (perfil == EPerfilInvestimento.Conservador) {
            resultPerfilText += "CONSERVADOR";
        }
        else if (perfil == EPerfilInvestimento.Moderado) {
            resultPerfilText += "MODERADO";
        }
        else {
            resultPerfilText += "ARROJADO";
        }
        return resultPerfilText;
    }

    getPerfilInvestimento(pontuacao: number) {
        if (pontuacao <= 24) {
            return EPerfilInvestimento.Conservador;
        }
        else if (pontuacao >= 25 && pontuacao <= 42) {
            return EPerfilInvestimento.Moderado;
        }
        else {
            return EPerfilInvestimento.Arrojado;
        }
    }

}
