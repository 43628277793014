import {RegistrarAlteracaoPerfilInvestimento } from './../../models/sinqia-api/registrar-alteracao-perfil-investimento.dto';
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHandler,HttpClientModule } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { DadosCadastraisSinqiaDto } from "../../models/sinqia-api/dados-cadastrais-sinqia.dto";
import { SessionStorageService } from "./../session-storage.service";
import { STORAGE_KEYS, TipoSeDocumento } from "../../constants";
import { AdesaoSinqiaDto, AlterarPerfilInvestimentoDTO, CargoPessoaPoliticamenteExpostaDto, ContribuicaoDto, DependenteBeneficiarioModel, PessoaDto, PessoaPoliticamenteExpostaDto, SolicitacaoAdesaoCdModel } from "../../models/sinqia-api";
import { BaseService } from "./base.service";
import { EPerfilInvestimento, ERegimeTributario, ETipoDependente, ETipoPlano, ETokenApi } from "../../enums";
import { ResponseApiCdModel } from "../../models/response-api-plano-cd.model";
import { PerguntasPpe } from "../../models/sinqia-api/perguntas-ppe.model";
import { PerguntaPerfilInvestimento } from "../../models/sinqia-api/pergunta-perfil-investimento";
import { HttpClientService } from "../http-client.service";
import { ContribuicaoAtivoCD } from "../../models/sinqia-api/contribuicao-ativo-cd.dto";
import { Action1, Func } from "../../types";
import { DependenteDto } from "../../models/sinqia-api/dependente-dto";
import { FileUploadModel } from "../../models/file-upload.model";
import * as _ from "lodash";

@Injectable()
export class AdesaoCdService extends BaseService {
    fileMimeTypePdf: string;
    solicitacaoAdesao: BehaviorSubject<SolicitacaoAdesaoCdModel | null> = new BehaviorSubject<SolicitacaoAdesaoCdModel | null>(new SolicitacaoAdesaoCdModel());

    constructor(protected httpClient: HttpClient, @Inject('environment') protected environment,
        private sessionStorageService: SessionStorageService,
        private httpClientService: HttpClientService) {
        super(httpClient, environment);
    }

    carregarDadosAdesaoPlanoCd(dadosCad: DadosCadastraisSinqiaDto) {
        this.salvarSolicitacaoAdesao(dadosCad);
    }

    getSolicitacaoAdesao() {
        if (this.sessionStorageService.get(STORAGE_KEYS.ADESAO_PLANO_CD)) {
            let adesao = this.sessionStorageService.getSecureParse<SolicitacaoAdesaoCdModel>(STORAGE_KEYS.ADESAO_PLANO_CD);
            this.solicitacaoAdesao.next(adesao);
            if (adesao && adesao.perguntasPerfilInvestimento == null) {
                adesao.perguntasPerfilInvestimento = [];
            }
            return this.solicitacaoAdesao.value;
        }
        return null;
    }

    private salvarSolicitacaoAdesao(dadosCad: DadosCadastraisSinqiaDto) {
        let adesaoDto = this.montarSolicitacaoAdesao(dadosCad)
        this.solicitacaoAdesao.next(adesaoDto);
        this.sessionStorageService.setAnyAsJsonSecure(STORAGE_KEYS.ADESAO_PLANO_CD, adesaoDto);
    }

    atualizarSolicitacaoAdesao(solicitacaoAde: SolicitacaoAdesaoCdModel) {
        this.solicitacaoAdesao.next(solicitacaoAde);
        this.sessionStorageService.setAnyAsJsonSecure(STORAGE_KEYS.ADESAO_PLANO_CD, solicitacaoAde);
    }

    atualizarSolicitacaoAdesaoAction(action: Action1<SolicitacaoAdesaoCdModel>): boolean {
        let solicitacaoAde = this.getSolicitacaoAdesao() as SolicitacaoAdesaoCdModel;
        action(solicitacaoAde);
        this.atualizarSolicitacaoAdesao(solicitacaoAde);
        return true;
    }

    atualizarSolicitacaoAdesaoFunc(func: Func<SolicitacaoAdesaoCdModel, boolean>): boolean {
        let solicitacaoAde = this.getSolicitacaoAdesao() as SolicitacaoAdesaoCdModel;
        let sucesso = func(solicitacaoAde);
        if (sucesso)
            this.atualizarSolicitacaoAdesao(solicitacaoAde);
        return sucesso;
    }

    private montarSolicitacaoAdesao(dadosCad: DadosCadastraisSinqiaDto) {
        let adesaoDto: SolicitacaoAdesaoCdModel = {
            concordoTermo: false,
            perguntasPpe: [],
            termoPerfilConservador: null,
            termoDesenquadramento: null,
            perguntasPerfilInvestimento: [],
            naoResponderQuestionario: false,
            token: null,
            //tipoRegimeTributario: ERegimeTributario.Progressivo,
            pessoa: {
                cpf: dadosCad.cpf,
                rg: dadosCad.identidade.numero,
                estadoCivil: dadosCad.estadoCivil,
                dataNascimento: dadosCad.dataNascimento,
                email: dadosCad.contato.email,
                nomeCompleto: dadosCad.nome,
                sexo: dadosCad.sexo,
                telefoneCelular: dadosCad.contato.celular,
                politicamenteExposto: {
                    exposto: null,
                    cargoId: null
                },
                endereco: {
                    bairro: dadosCad.endereco.bairro,
                    cep: dadosCad.endereco.cep,
                    cidade: dadosCad.endereco.cidade,
                    complemento: dadosCad.endereco.complemento,
                    logradouro: dadosCad.endereco.logradouro,
                    numero: dadosCad.endereco.numero,
                    tipoLogradouro: dadosCad.endereco.tipoLogradouro
                }
            },
            dependentes: []
        };

        return adesaoDto;
    }

    addDependente(dependente: DependenteDto) {
        return this.atualizarSolicitacaoAdesaoFunc((solicitacaoAde) => {
            let dependenteExiste = _.some(solicitacaoAde.dependentes,
                {
                    cpf: dependente.cpf, nome: dependente.nome,
                    tipoDependente: dependente.tipoDependente,
                    sexo: dependente.sexo
                });
            if (!dependenteExiste) {
                solicitacaoAde.dependentes.push(dependente);
                return true;
            }
            return false;
        });
    }

    atualizarDependente(dependente: DependenteDto) {
        return this.atualizarSolicitacaoAdesaoFunc((solicitacaoAde) => {
            let existeDependente = _.some(solicitacaoAde.dependentes, { codigo: dependente.codigo });
            if (existeDependente) {
                _.remove(solicitacaoAde.dependentes, { codigo: dependente.codigo });
                solicitacaoAde.dependentes.push(dependente);
                return true;
            }
            return false;
        });
    }

    addDependentes(dependentes: DependenteDto[]) {
        dependentes.forEach(dep => {
            this.addDependente(dep);
        });
    }

    addDocumentoDependente(codigoDep: string, documento: FileUploadModel) {
        return this.atualizarSolicitacaoAdesaoFunc((solicitacaoAde) => {
            let dependente = _.find(solicitacaoAde.dependentes, { codigo: codigoDep });
            if (dependente) {
                dependente.documentos?.push(documento);
                return true;
            }
            return false;
        });
    }

    removerDocumentoDependente(codigoDep: string, documentoId: string) {
        return this.atualizarSolicitacaoAdesaoFunc((solicitacaoAde) => {
            let dependente = _.find(solicitacaoAde.dependentes, { codigo: codigoDep });
            if (dependente) {
                _.remove(dependente.documentos, { id: documentoId });
                return true;
            }
            return false;
        });
    }

    getDocumentosDependente(codigoDep: string) {
        return _.find(this.getSolicitacaoAdesao()?.dependentes, { codigo: codigoDep })?.documentos ?? [];
    }

    getDependentes() {
        return this.getSolicitacaoAdesao()?.dependentes;
    }

    limparDependentes(tipoDependente: ETipoDependente) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            _.remove(solicitacaoAde.dependentes, { tipoDependente: tipoDependente });
        });
    }

    removerDependente(codigo: string) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            if (!_.isEmpty(solicitacaoAde.dependentes))
                _.remove(solicitacaoAde.dependentes, x => x.codigo == codigo);
        });
    }

    // definirRegimeTributario(regimeTributario: ERegimeTributario) {
    //     this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
    //         solicitacaoAde.tipoRegimeTributario = regimeTributario;
    //     });
    // }

    definirPerfilInvestimento(perfilInvestimento: EPerfilInvestimento | null, perfilInvestimentoIndicado: EPerfilInvestimento | null) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.perfilInvestimento = perfilInvestimento;
            solicitacaoAde.perfilInvestimentoIndicado = perfilInvestimentoIndicado;
        });
    }

    validarSolicitarAdesao(solicitacaoAdesao: SolicitacaoAdesaoCdModel) {
        const endpoint = `${this.endpoint}/adesao/validar-adesao`;
        return this.httpClientService.post<ResponseApiCdModel<boolean>>(endpoint, solicitacaoAdesao, ETokenApi.TokenApiCd);
    }

    definirPessoaPoliticamenteExposta(exposto: boolean | null) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            let pessoa = solicitacaoAde.pessoa;
            if (pessoa?.politicamenteExposto) {
                pessoa.politicamenteExposto.exposto = exposto
                if (!exposto)
                    pessoa.politicamenteExposto.cargoId = null;
            } else if (pessoa) {
                pessoa.politicamenteExposto = {
                    exposto: exposto,
                    cargoId: null
                };
            }
            solicitacaoAde.pessoa = pessoa;
        });
    }

    definirAceiteTermo(aceito: boolean) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.concordoTermo = aceito;
        });
    }

    definirAceiteTermoPerfilConservador(aceito: boolean) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.termoPerfilConservador = aceito;
        });
    }

    definirTermoDesenquadramento(aceito: boolean) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.termoDesenquadramento = aceito;
        });
    }

    ignorarPerguntasPerfilInvestimento() {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.perguntasPerfilInvestimento = null;
            solicitacaoAde.naoResponderQuestionario = false;
            solicitacaoAde.perfilInvestimento = EPerfilInvestimento.Conservador;
        });
    }

    definirNaoResponderQuestionario(opcao: boolean) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.naoResponderQuestionario = opcao;
        });
    }

    solicitarAdesao(solicitacaoAdesao: SolicitacaoAdesaoCdModel) {
        const endpoint = `${this.endpoint}/adesao/solicitar-adesao`;
        return this.httpClientService.post<ResponseApiCdModel<boolean>>(endpoint, solicitacaoAdesao, ETokenApi.TokenApiCd);
    }

    salvarPerguntasPpe(perguntasPpe: Array<PerguntasPpe>) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.perguntasPpe = perguntasPpe;
        });
    }

    limparDadosAdesaoLocal() {
        this.sessionStorageService.remove(STORAGE_KEYS.ADESAO_PLANO_CD);
    }

    atualizarPerguntasPerfilInvestimento(perguntas: Array<PerguntaPerfilInvestimento>) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            solicitacaoAde.perguntasPerfilInvestimento = perguntas;
        });
    }

    atualizarCargoPpe(cargoPpeId: number) {
        this.atualizarSolicitacaoAdesaoAction((solicitacaoAde) => {
            let pessoa = solicitacaoAde.pessoa;
            if (pessoa?.politicamenteExposto) {
                pessoa.politicamenteExposto.cargoId = cargoPpeId;
            } else if (pessoa) {
                pessoa.politicamenteExposto = {
                    exposto: null,
                    cargoId: cargoPpeId
                };
            }
            solicitacaoAde.pessoa = pessoa;
        });
    }

    atualizarToken(token: string | null) {
        let solicitacaoAde = this.getSolicitacaoAdesao() as SolicitacaoAdesaoCdModel;
        solicitacaoAde.token = token;
        this.atualizarSolicitacaoAdesao(solicitacaoAde);
    }

    atualizarRegimeTributario(novoRegimeTributario: {
        idAdesao: number,
        novaDataOpcao: Date,
        //formaOpcaoRegime: string,
        //regimeTributario: string
    }) {
        const endpoint = `${this.endpoint}/adesao/atualizar-regime-tributario/${novoRegimeTributario.idAdesao}`;
        return this.httpClientService.put<ResponseApiCdModel<AdesaoSinqiaDto>>(endpoint, novoRegimeTributario, ETokenApi.TokenApiCd);
    }

    obtemAdesaoPorId() {
        const endpoint = `${this.endpoint}/adesao/obter-adesao-por-id`;
        return this.httpClientService.get<ResponseApiCdModel<AdesaoSinqiaDto>>(endpoint, ETokenApi.TokenApiCd);
    }

    obterExtratoContribuicaoPorAno(ano) {
        this.endpoint = this.environment.API_CONTRIBUICAO;
        const url = `${this.endpoint}/Extrato/ExtratoContribuicaoPorAno?ano=${ano}`;
        return this.httpClientService.get<ContribuicaoDto>(url, ETokenApi.TokenApiBd);
    }

    obterExtratoContribuicaoAtivoCD(ano) {
        const endpoint = `${this.endpoint}/adesao/obter-contribuicao-ativo-cd?ano=${ano}`;
        return this.httpClientService.get<ResponseApiCdModel<ContribuicaoAtivoCD>>(endpoint, ETokenApi.TokenApiCd);
    }

    solicitarSuspensaoDaConstribuicao(dataInicioVigencia: Date, documentosComprobatorios: File[]) {
        const endpoint = `${this.endpoint}/adesao/solicitar-suspensao-contribuicao`;
        var form = new FormData();
        form.append("solicitacaoSuspensaoDto", JSON.stringify({
            observacao: "",
            id: 0,
            idAdesao: 0,
            dataSolicitacao: dataInicioVigencia,
            competencia: dataInicioVigencia,
            vigencia: {
                caption: "",
                dataInicial: dataInicioVigencia,
                dataFinal: "",
                guid: "",
                text: ""
            }
        }));
        documentosComprobatorios.forEach((file, index) => {
            form.append('documentosComprobatorio', file);
        });
        return this.httpClientService.upload<any>(endpoint, form, ETokenApi.TokenApiCd);
    }

    ObterPerfilInvestimento() {
        const endpoint = `${this.endpoint}/beneficio/obter-perfil-investimento`;
        return this.httpClientService.get<ResponseApiCdModel<AlterarPerfilInvestimentoDTO>>(endpoint, ETokenApi.TokenApiCd);
    }

    registrarAlterarPerfilInvestimento(registrarAlterarPerfilInvestimento: RegistrarAlteracaoPerfilInvestimento)
    {
        const url = `${this.endpoint}/adesao/registrar-alteracao-perfil-investimento`;
        return this.httpClientService.post<ResponseApiCdModel<boolean>>(url, registrarAlterarPerfilInvestimento, ETokenApi.TokenApiCd);
    }

    efetivarRegistroPerfilInvestimento(idsAdesao: number[]): Observable<any> {
        const url = `${this.endpoint}/adesao/migrar-alteracao-perfil-investimento`;
        return this.httpClientService.post(url, idsAdesao,ETokenApi.TokenApiCd);
    }

    obterRegistrosAlteracaoPerfilInvestimento(): Observable<RegistrarAlteracaoPerfilInvestimento[]> {
        const url = `${this.endpoint}/adesao/obter-registros-perfil-investimento`;
        return this.httpClientService.get<any[]>(url,ETokenApi.TokenApiCd);
    }

    obterRegistroAlteracaoPerfilInvestimento(idAdesao: number): Observable<any> {
        const url = `${this.endpoint}/adesao/obter-registro-alteracao-perfil-investimento`;
        return this.httpClientService.get<any>(`${url}/${idAdesao}`,ETokenApi.TokenApiCd);
      }

    downloaRegistroPerfilInestimento(): Observable<Blob> {
        const url = `${this.endpoint}/adesao/download-registro-perfil-investimento`;
        return this.httpClient.get(url, {
          responseType: 'blob'
        });
    }

    validarPercentualDependente(dependentes: DependenteDto[]) {
        return _.isEmpty(dependentes) || _.sumBy(dependentes, dep => dep.percentualParticipacao as number) == 100;
    }
}
