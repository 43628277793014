import { Component, Input, OnInit } from '@angular/core';
import {
    GestaoRelacionamentoComunicacaoService,
    ERRO_CRITICO_DE_CARREGAMENTO,
    SimulacaoPercentual,
    GestaoFinanceiraEmprestimoService
} from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-modal-token',
  templateUrl: './modal-token.component.html',
  styleUrls: ['./modal-token.component.css']
})
export class ModalTokenComponent implements OnInit {
    @Input() exibirModal: boolean;
    @Input() planoDeParcelamentoEscolhido: SimulacaoPercentual;
    @Input() set email(email: any) {
        if (email) {
            this.textoMensagem(email);
        }
    }

    readonly MENSAGEM_ERRO = ERRO_CRITICO_DE_CARREGAMENTO;
    erro: boolean;
    msgErro: string;
    submitAttempted = false;
    confirmacao: boolean;
    naoMostrar = true;
    token: number;
    tokenInvalido = false;
    dataVigente: Date;
    emailMascarado: string;
    mensagemLinha1: string;
    mensagemLinha2: string;
    contDigitos = 0;
    habilitaBotao = false;
    classeBotao = 'btn-success';
    isLoading = true;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
                private gestaoRelacionamentoComunicacaoService: GestaoRelacionamentoComunicacaoService) { }

    ngOnInit() {}

    textoMensagem(email: any) {
        this.isLoading = false;
        const emailSplit = email.split('@');
        this.emailMascarado = emailSplit[0].slice(0, 2) + '*****@' + emailSplit[1];
        this.mensagemLinha1 = 'Enviamos um código de verificação para o e-mail';
        this.mensagemLinha2 = this.emailMascarado;
    }

    confirmarToken() {
        this.isLoading = true;
        this.submitAttempted = true;
        this.gestaoRelacionamentoComunicacaoService.Verificar_SMSValido(this.token).subscribe(data => {
            if (data && data === true) {
                this.alterarPercentual();
                this.tokenInvalido = false;
            } else {
                this.isLoading = false;
                this.token = null;
                this.tokenInvalido = true;
            }
        }, () => {
            this.isLoading = false;
            this.erro = true;
            this.msgErro = this.MENSAGEM_ERRO;
            this.tokenInvalido = true;
        });
    }

    alterarPercentual() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_AlterarPercentual(this.planoDeParcelamentoEscolhido).subscribe(simulacao => {
            this.dataVigente = new Date(this.planoDeParcelamentoEscolhido.dataReferencia);
            this.dataVigente.setMonth(this.dataVigente.getMonth() + 1);
            if (simulacao) {
                this.confirmacao = true;
                this.isLoading = false;
            }
        }, () => {
            this.isLoading = false;
            this.erro = true;
            this.msgErro = this.MENSAGEM_ERRO;
        });
    }

    enviarPorEmail() {
        this.isLoading = true;
        this.gestaoRelacionamentoComunicacaoService.Enviar_SMSPorEmail().subscribe(data => {
            if (data && data.descricao !== 'Ok') {
                this.erro = true;
                this.msgErro = data.descricao;
                this.isLoading = false;
                return;
            }
            this.token = null;
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
            this.erro = true;
            this.msgErro = this.MENSAGEM_ERRO;
        });
    }

    fecharModal() {
        this.exibirModal = false;
        this.naoMostrar = false;
        window.location.reload();
    }

    validaDigitos(e) {
        this.contDigitos = e.length;
        const element = document.getElementById('btnEnviar');
        if (this.contDigitos === 6) {
            element.classList.add(this.classeBotao);
            this.habilitaBotao = true;
        } else {
            element.classList.remove(this.classeBotao);
            this.habilitaBotao = false;
        }
    }
}
