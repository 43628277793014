import { Campanha } from './../models/campanha.model';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AuthServiceLoginResponse, CadastrarCpfModel, ConfirmarCpfModel, UserAd } from '../models';
import { Service } from './service';
import { RequestCacheService } from './request-cache.service';
import { Observable } from 'rxjs';
import {
    ProviderResponseModel,
    SendEmailCodeModel,
    SendEmailCodeResponseModel,
    SendSmsCodeModel,
    SendSmsCodeResponseModel,
    TwoFactorAuthTokenModel,
    VerifyCodeModel,
    VerifyCodeResponseModel
} from './../models/fibra-identity';
import { SessionInitService } from './session-init.service';
import { AdesaoLoginResponse } from '../models/fibra-identity/adesao-login-response.model';
import { tap } from 'rxjs/operators';
import { DadosCadastraisService } from './dados-cadastrais.service';
import { StorageService } from './storage.service';
import { HEADERS, STORAGE_KEYS, TipoSituacaoParticipante } from '../constants';
import { SessionStorageService } from './session-storage.service';
import { ESituacaoParticipante } from '../enums';


@Injectable()
export class AuthService extends Service {
    static readonly USUARIO_ATIVO = 'auth_autoatendimento_ativo';
    static readonly USUARIO_ASSISTIDO = 'auth_autoatendimento_assistido';
    static readonly USUARIO_GERENCIAL = 'auth_gerencial';
    static readonly USUARIO_PENSIONISTA = 'auth_autoatendimento_pensionista';
    static readonly USUARIO_FIBRA = 'auth_fibra';
    static readonly USUARIO_DEX = 'auth_dex';
    static readonly MOSTRAR_ACOMPANHAMENTO = 'mostrar_acompanhamento';
    static readonly MOSTRAR_PAGAMENTOS = 'mostrar_pagamentos';
    static readonly MOSTRAR_BAIXAR_APP = 'mostrar_baixar_app';
    static readonly USUARIO_PCN = 'auth_pcn';
    static readonly EXISTE_PENDENCIA_APROVACAO = 'existePendenciaAprovacao';

    static readonly MOSTRAR_EMPRESTIMO = 'auth_usuario_autopatrocinado';

    papeisUsuario: string[] = [];

    private url: string;

    constructor(
        @Inject('environment') private environment,
        http: HttpClient,
        private router: Router,
        private sessionInitService: SessionInitService,
        private dadosCadastraisService: DadosCadastraisService,
        private storageService: StorageService,
        private sessionStorageService: SessionStorageService) {
        super(http);
        this.url = this.environment.API_LOGIN;
    }

    login(username: string, cpf: string, password: string, regFibra: string = '', recaptchaToken: string = ''): Observable<AuthServiceLoginResponse> {
        var pwd = encodeURIComponent(password);

        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        return this.httpClient.post<AuthServiceLoginResponse>(
            `${this.url}/token`,
            new HttpParams({
                fromObject: {
                    grant_type: 'password',
                    username: username.replace(/[^0-9]/g, ''),
                    cpf: cpf.replace(/[^0-9]/g, ''),
                    password: pwd,
                    regFibra,
                    recaptchaToken: recaptchaToken
                }
            }),
            { headers }
        );
    }

    logout(urlPrevius?: string) {
        RequestCacheService.clearCache();
        sessionStorage.clear();
        this.dadosCadastraisService.limparDadosCadastraisLocalStorage();
        this.sessionStorageService.clear();
        this.storageService.remove(STORAGE_KEYS.HANDSHAKE_TOKEN);
        this.router.navigate(['/login'], { queryParams: { returnUrl: urlPrevius } });
    }

    getLogout(): Observable<boolean> {
        return this.get<boolean>(`${this.url}/api/Account/Logout`);
    }

    getRegFibra(): Observable<any> {
        return this.get<any>(`${this.url}/api/Account/GetRegFibra`);
    }

    getLinkIos(regFibra: number): Observable<string> {
        return this.get<string>(`${this.url}/api/AppIosLink/GetOneLink/${regFibra}`);
    }

    getCampanha(version: string, platform: string): Observable<Campanha> {
        return this.get<boolean>(`${this.url}/api/Campanha/GetCampanha/${version}/${platform}`);
    }

    atualizarIOS(versionIos: string, appleIos: boolean): Observable<boolean> {
        return this.get<boolean>(`${this.url}/api/AppIosLink/CheckAtualizacaoIos/${versionIos}/${appleIos}`);
    }

    getTokenIntegracao(username: string, cpf: string, password: string, regFibra: string = ''): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        return this.httpClient.post<AuthServiceLoginResponse>(
            `${this.url}/token`,
            new HttpParams({
                fromObject: {
                    grant_type: 'password',
                    username: username.replace(/[^0-9]/g, ''),
                    cpf: cpf.replace(/[^0-9]/g, ''),
                    password,
                    regFibra,
                    integracao: 'true'
                }
            }),
            { headers }
        );
    }

    isAuthenticated() {
        const token = sessionStorage.getItem('accessToken');
        const tokenExpirationDate = sessionStorage.getItem('tokenExpirationDate');
        const tokenIsNotExpired = !!tokenExpirationDate && Date.now() < new Date(tokenExpirationDate).getTime();
        return token && tokenIsNotExpired;
    }

    AccountCPF_ConfirmarAlterarSenha(
        cpf: string,
        oldPassword: string,
        password: string,
        rePassword: string
    ): Observable<any> {
        let cpfNumers = cpf.replace(/\D/g, '');
        return this.post<{ message: string; type: number }>(`${this.url}/api/AccountCPF/ConfirmarAlterarSenha`, {
            cpf: cpfNumers,
            oldPassword: oldPassword,
            password: password,
            rePassword: rePassword
        });
    }

    AccountCPF_ConfirmarEsqueciSenha(id: string, token: string, password: string, rePassword: string): Observable<any> {
        return this.post<any>(`${this.url}/api/AccountCPF/ConfirmarEsqueciSenha`, {
            id: id,
            token: token,
            password: password,
            rePassword: rePassword
        });
    }

    AccountCPF_ConfirmarRegistro(confirmarCpfModel: ConfirmarCpfModel): Observable<any> {
        return this.post<any>(`${this.url}/api/AccountCPF/ConfirmarRegistro`, confirmarCpfModel);
    }

    AccountCPF_EsqueciSenha(cpf: string): Observable<any> {
        return this.post<any>(
            `${this.url}/api/AccountCPF/EsqueciSenha`,
            {
                cpf: cpf.replace(/[^0-9]/g, ''),
                urlConfirmation: this.environment.URL_DO_AMBIENTE + '/confirmar-senha'
            },
            false
        );
    }

    AccountCPF_registro(cadastrarCpfModel: CadastrarCpfModel): Observable<any> {
        return this.post<any>(`${this.url}/api/AccountCPF/registro`, {
            cpf: cadastrarCpfModel.cpf.replace(/[^0-9]/g, ''),
            urlConfirmation: `${this.environment.URL_DO_AMBIENTE}/confirmar-senha`
        });
    }

    AccountCPF_GetToken(usuarioId: string): Observable<any> {
        const urlAmbiente = `${this.environment.URL_DO_AMBIENTE}/confirmar-senha`;
        const url = `${this.url}/api/AccountCPF/GetToken?usuarioId=${usuarioId}&urlAmbiente=${urlAmbiente}`;
        return this.get<any>(url, false);
    }

    //#region Autenticação de dois fatores.

    /**
     * Obtém os provedores de autenticação.
     * @param userName nome de usuário.
     * @returns OK
     */
    MultiFactorAuth_Providers(userName: string) {
        const url = `${this.url}/api/MultiFactorAuth/Providers/${userName}`;
        return this.httpClient.get<ProviderResponseModel>(url);
    }

    /**
     * Envia código de segurança via e-mail.
     * @param sendEmailCodeModel model de envio de código de segurança.
     * @returns OK
     */
    MultiFactorAuth_SendEmailCode(sendEmailCodeModel: SendEmailCodeModel, plano: string = '') {
        const url = `${this.url}/api/MultiFactorAuth/SendEmailCode`;
        let httpHeaders = new HttpHeaders();
        if (plano && plano.length > 0) {
            httpHeaders = httpHeaders.append(HEADERS.SIGLA_PLANO, plano.toUpperCase());
        }
        return this.httpClient.post<SendEmailCodeResponseModel>(url, sendEmailCodeModel, { headers: httpHeaders });
    }

    /**
     * Envia código de segurança via SMS.
     * @param sendSmsCodeModel model de envio de código de segurança.
     * @returns OK
     */
    MultiFactorAuth_SendSmsCode(sendSmsCodeModel: SendSmsCodeModel) {
        const url = `${this.url}/api/MultiFactorAuth/SendSmsCode`;
        return this.httpClient.post<SendSmsCodeResponseModel>(url, sendSmsCodeModel);
    }

    /**
     * Verifica código de segurança.
     * @param verifyCodeModel model de verificação de código de segurança.
     * @returns OK
     */
    MultiFactorAuth_VerifyCode(verifyCodeModel: VerifyCodeModel) {
        const url = `${this.url}/api/MultiFactorAuth/VerifyCode`;
        return this.httpClient.post<VerifyCodeResponseModel>(url, verifyCodeModel);
    }

    /**
     * Verifica se o token gerado por autenticação de dois fatores é válido.
     * @returns
     */
    MultiFactorAuth_HasValidToken() {
        let token = this.sessionInitService.sessionGetTwoFactorAuthToken();
        if (token && token.code && token.expires && token.provider && token.success) {
            let expiresDate = new Date(token.expiresTime);
            return new Date() < expiresDate;
        }
        return false;
    }

    /**
     * Obtém o token da sessão gerado na autenticação de dois fatores.
     * @returns
     */
    MultiFactorAuth_GetToken(): TwoFactorAuthTokenModel {
        let token = this.sessionInitService.sessionGetTwoFactorAuthToken();
        return token;
    }
    //#endregion

    GetLoginAdesoes(cpf: string) {
        const url = `${this.url}/api/Account/GetLoginAdesoes/${cpf}`;
        return this.httpClient.get<AdesaoLoginResponse>(url);
    }
}
