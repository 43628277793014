import { Component, OnInit } from '@angular/core';
import { AlterarPerfilInvestimentoDTO } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/alterar-perfil-investimento.dto';
import * as _ from "lodash";
import { SolicitacaoAlteracaoPerfilInvestimentoDTO } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/solicitar-alteracao-perfil-investimento.dto';
import { ResumoAdesaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/resumo-adesao.dto';
import { AdesaoCdService, AdesaoService, EPerfilInvestimento, retornaChaveEnumerador } from '@fibra/fibra-shared-lib';
import { RegistrarAlteracaoPerfilInvestimento } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { format } from 'date-fns';

@Component({
    selector: 'app-perfil-investimento-card',
    styleUrls: ['./perfil-investimento-card.component.css'],
    templateUrl: './perfil-investimento-card.component.html'
})
export class PerfilInvestimentoCardComponent implements OnInit {
    mensagemDeErro: string;
    isLoading = true;
    show = true;
    bloquear: boolean;
    perfilInvestimentoDTO: AlterarPerfilInvestimentoDTO;
    ultimoPerfilInvestimento: SolicitacaoAlteracaoPerfilInvestimentoDTO;
    solicitacoes: SolicitacaoAlteracaoPerfilInvestimentoDTO[];
    ultimaData: Date;
    resumoAdesao: ResumoAdesaoDto;
    registroAlteracaoPerfilInvestimento: any[] = [];

    constructor(private adesaoCDService: AdesaoCdService, private adesaoService: AdesaoService) {

	}

    ngOnInit() {
        this.isLoading = true;
        this.ObterPerfilInvestimento();
        this.carregarHistorico();
    }

    ObterPerfilInvestimento(){        
        this.resumoAdesao = this.adesaoService.getAdesaoContexto();
        this.adesaoCDService.ObterPerfilInvestimento().subscribe(perfilInvestimento => {
            this.isLoading = true;
            if(perfilInvestimento.data)
            {
                this.perfilInvestimentoDTO = perfilInvestimento.data;

                if(this.perfilInvestimentoDTO.solicitacoes.length > 0) {

                    const dataCompetencia = new Date(this.resumoAdesao?.dataInscricao);

                    this.solicitacoes =[{
                        perfilInvestimento: this.perfilInvestimentoDTO?.descricaoPerfilAnterior,
                        dataCompetencia: format(dataCompetencia, 'dd/MM/yyyy')
                    }]

                    this.solicitacoes = this.solicitacoes.concat(this.perfilInvestimentoDTO.solicitacoes);

                    this.ultimoPerfilInvestimento = _.last(this.solicitacoes);
                }
                else
                {

                    const dataInscricao = new Date(this.resumoAdesao.dataInscricao)

                    this.ultimoPerfilInvestimento = {
                        perfilInvestimento: this.perfilInvestimentoDTO?.descricaoPerfilAnterior,
                        dataCompetencia: format(dataInscricao, 'dd/MM/yyyy')
                       }

                       this.solicitacoes =[{
                        perfilInvestimento: this.perfilInvestimentoDTO?.descricaoPerfilAnterior,
                        dataCompetencia: format(dataInscricao, 'dd/MM/yyyy')
                    }]
                }

            }
            this.isLoading = false;
        })
    }

    carregarHistorico(): void {
        debugger;
        const idAdesao =  this.adesaoService.getAdesaoContexto().idAdesao;
        let perfilInvestimentoPendente: RegistrarAlteracaoPerfilInvestimento;

        this.adesaoCDService.obterRegistroAlteracaoPerfilInvestimento(idAdesao).subscribe(
          (data) => {
            // Processa os dados recebidos
            perfilInvestimentoPendente = data.result;

            if(!perfilInvestimentoPendente.migrado)
            {
                const perfilInvestimento = retornaChaveEnumerador(EPerfilInvestimento, perfilInvestimentoPendente?.idPerfilInvestimento);                

                this.solicitacoes.push({
                    perfilInvestimento: perfilInvestimento,
                    dataCompetencia: "Em Processamento"
                });
            }
          },
          (error) => {
            console.error('Erro ao obter histórico de alterações:', error);
          }
        );
      }
}
