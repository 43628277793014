import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormularioService, AlertModalComponent, RespostaDocumentoCriadoModel, SignDataModel, AdesaoService } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { SuspensaoContribuicaoModel } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';
import { FileUpload } from 'primeng/fileupload';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';
import { SignWidgetComponent } from 'projects/FibraAutoAtendimento/src/app/components/clicksign/sign-widget/sign-widget.component';

@Component({
    selector: 'app-prorrogacao-adiantamento-parcial',
    templateUrl: './prorrogacao-adiantamento-parcial.component.html',
    styleUrls: ['./prorrogacao-adiantamento-parcial.component.css']
})

export class ProrrogacaoAdiantamentoParcialComponent implements OnInit {
    isSimulado = sessionStorage.getItem('isSimulado');
    documentoAssinado: boolean = false;
    mostrarSignWidget: boolean = false;
    isLoading: boolean = false;
    urlArquivoSeSuite: string;
    yearRange: string;
    msgs: any[];
    uploadedFiles: any[] = [];
    suspensaoContribuicao: SuspensaoContribuicaoModel;
    prorrogacaoAdiantamentoForm: FormGroup;
    ptBR = LOCALE_PT_BR;
    adesaoContext: any;
    registroFibraContaParticipante: any;

    @ViewChild('signWidget') signWidget: SignWidgetComponent
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    @ViewChild('twoFactorAuth') twoFactorAuth: TwoFactorAuthComponent;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    
    constructor(private formularioService: FormularioService,
        private authService: AuthService,
        private fb: FormBuilder,
        private adesaoService: AdesaoService,
        private datePipe: DatePipe
    ) {
        this.yearRange = '1900:' + (new Date()).getFullYear();
    }

    ngOnInit() {
        this.prorrogacaoAdiantamentoForm = this.fb.group({
            dataConcessaoAdiantamento: new FormControl(null, [Validators.required])
        });

        this.adesaoContext = this.adesaoService.getAdesaoContexto();
        
        this.registroFibraContaParticipante = this.adesaoContext.dadosFibra.registroFibra;
    }

    ngOnDestroy(): void {
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    registrarDocumentoAssinado(signData: SignDataModel) {
        this.formularioService.registrarAssinaturaDocumento(signData.idDocumento)
            .subscribe(resposta => {
                this.documentoAssinado = resposta.success && resposta.data;
            }, error => {
                console.error(error);
            });
    }
    
    enviar() {
        if (!this.prorrogacaoAdiantamentoForm.valid) {
            this.alertModalComponent.show('É necessário preencher a data da concessão do adiantamento.', false);
            return;
        }

        if (!this.fileUpload.hasFiles()) {
            this.alertModalComponent.show('É necessário anexar o comprovante.', false);
            return;
        }

        let dataConcessaoAdiantamento: Date = this.prorrogacaoAdiantamentoForm.get('dataConcessaoAdiantamento').value;

        this.isLoading = true;
        let formData = new FormData();
        formData.append('dataConcessaoAdiantamento', dataConcessaoAdiantamento.toLocaleDateString('pt-BR'));

        if (this.fileUpload.hasFiles()) {
            this.fileUpload.files.forEach((file, index) => {
                formData.append('anexos_' + index, file);
            });

            this.formularioService.gerarFormProrrogacaoAdiantamentoParcial(this.registroFibraContaParticipante, formData)
                .subscribe((resposta: RespostaDocumentoCriadoModel) => {
                    this.isLoading = false;
                    if (this.isSimulado === 'true') {
                        window.open(resposta.urlDoDocumentoNaoAssinado, '_blank');
                    }
                    if(resposta.sucesso && (this.isSimulado == 'false' || this.isSimulado == null)){
                        this.mostrarSignWidget = true;
                        setTimeout(() => {
                            this.signWidget.carregarWidget({
                                signKey: resposta.idDaRequisicaoDaAssinatura,
                                idDocumento: resposta.idDoDocumento
                            });
                        }, 500);
                    }
                    this.reset();
                }, () => {
                    this.mostrarSignWidget = false;
                    this.isLoading = false;
                    this.reset();
                });
        }
            
    }

    reset() {
        this.ngOnInit();
        this.fileUpload.clear();
        this.uploadedFiles = [];
    }

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem('info', 'File Uploaded', 'OK');
    }

}
