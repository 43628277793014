export class MotivadorAlterarPerfilInvestimentoDTO
{
    id: number;
    descricao: string;
    selecionado?: boolean | null;
    detalhes?: string | null; // Inclua este campo apenas para "Outros motivos"


    static getMotivos?(): Array<MotivadorAlterarPerfilInvestimentoDTO>{

        return [
            { id: 1, descricao: "Cenário Macroeconômico", selecionado: null },
            { id: 2, descricao: "Desempenho do Perfil", selecionado: null },
            { id: 3, descricao: "Recomendação Externa", selecionado: null },
            { id: 4, descricao: "Momento de Vida", selecionado: null },
            { id: 5, descricao: "Outros motivos", selecionado: null, detalhes: null }
        ];
    }

    static toJSON(motivos: Array<MotivadorAlterarPerfilInvestimentoDTO>): string {
        return JSON.stringify(motivos
          .map(motivo => ({
            id: motivo.id,
            descricao: motivo.descricao,
            selecionado: motivo.selecionado,
            detalhes: motivo.detalhes
          })));
      }

  }
