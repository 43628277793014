import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LinksIntegracao } from './../../../../../fibra-shared-lib/src/lib/constants/links-integracao';

import { AuthService, CadastrarCpfModel, CPF } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-cadastro',
    templateUrl: './cadastro.component.html',
    styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent {
    readonly OpenExternalURL = LinksIntegracao.WHATS_APP;

    formulario: FormGroup;
    isDialogVisible = false;
    isLoading = false;
    mensagem: string;
    solicitacaoDeCadastroEnviadaComSucesso: boolean;
    submitAttempted = false;
    esqueciSenha = false;
    emailInvalido = false;
    naoAtivouConta = false;
    jaCadastrado: boolean;

    readonly CPF = CPF;

    constructor(private authService: AuthService) {
        this.formulario = new FormGroup({
            cpf: new FormControl('', [
                Validators.required,
                Validators.minLength(CPF.LENGTH_WITHOUT_MASK),
                Validators.maxLength(CPF.LENGTH_WITHOUT_MASK)
            ])
        });
    }

    get cpf() {
        return this.formulario.get('cpf');
    }

    onSubmit() {
        this.submitAttempted = true;

        if (this.formulario.valid) {
            this.cadastrarCpf();
        }
    }

    private cadastrarCpf() {
        this.isLoading = true;
        const model = new CadastrarCpfModel(this.formulario.value.cpf);

        this.authService.AccountCPF_registro(model).subscribe(data => {
            this.mensagem = data.message;
            this.esqueciSenha = false;
            this.naoAtivouConta = false;
            this.emailInvalido = false;
            this.solicitacaoDeCadastroEnviadaComSucesso = data.type === 1 ? true : false;
            if (this.mensagem && this.mensagem.includes('Usuário já cadastrado.')) {
                this.mensagem = this.mensagem + ' Caso tenha esquecido sua senha ';
                this.esqueciSenha = true;
            }

            if (this.mensagem && this.mensagem.includes('Um novo link')) {
                this.naoAtivouConta = true;
            }

            if (this.mensagem && this.mensagem.includes('Seu email não')) {
                this.emailInvalido = true;
            }

            this.isDialogVisible = true;
            this.isLoading = false;
        }, (error) => {
            var mensagemErro = error.error.exceptionMessage.toString();
            if (mensagemErro.includes('Cadastrado')) {
                this.jaCadastrado = true;
                this.mensagem = "Este CPF já está cadastrado. ";
            } else {
                this.mensagem = "Algo deu errado. Por favor tente novamente mais tarde.";
            }
            this.solicitacaoDeCadastroEnviadaComSucesso = false;
            this.isDialogVisible = true;
            this.isLoading = false;
        });
    }

    openExternalURL(link: string) {
        window.open(link, 'blank');
    }
}
