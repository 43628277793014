import { Component, OnInit, ViewChild } from '@angular/core';
import {
    AdesaoParticipantePendenteAprovacaoResponse, AprovacaoDescontoFolhaRequest,
    SolicitacaoDescontoFolhaService, AlertModalComponent
} from '@fibra/fibra-shared-lib';
import { ParticipantePendenteAprovacaoRequest } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-solicitacao-desconto-folha',
    templateUrl: './solicitacao-desconto-folha-cd.component.html',
    styleUrls: ['./solicitacao-desconto-folha-cd.component.css']
})
export class SolicitacaoDescontoFolhaCDComponent implements OnInit {
    adesoesPendenteAprovacao: Array<AdesaoParticipantePendenteAprovacaoResponse>;
    adesoesPendenteAprovacaoPermissao: Array<{
        nomeParticipante: string;
        cpfParticipante: string;
        idAdesao: number;
        idTipoAdesao: number;
        idPlano: number;
        cpf: string;
        dataNascimento: string;
        nome: string;
        dataInscricao: string;
        aprovado: boolean;
    }> = [];
    isLoading: boolean;
    existeAprovacaoPendente: boolean = false;
    useMockData: boolean = false; // Flag para ativar dados mockados

    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;

    constructor(private solicitacaoDescontoFolhaService: SolicitacaoDescontoFolhaService) { }

    ngOnInit() {
        this.getParticipantesPendentesAprovacao();
    }

    getParticipantesPendentesAprovacao() {
        this.isLoading = true;
        this.adesoesPendenteAprovacaoPermissao = [];

        if (this.useMockData) {
            const mockData = this.getMockData();
            this.processarDados(mockData);
            this.isLoading = false;
            this.existeAprovacaoPendente = true;
        } else {
            const participantePendente: ParticipantePendenteAprovacaoRequest = { cpf: '', IdPlano: 3 };
            this.solicitacaoDescontoFolhaService.getParticipantesPendentesAprovacao(participantePendente)
                .subscribe(adesoesPartPendenteAprovacao => {
                    this.adesoesPendenteAprovacao = adesoesPartPendenteAprovacao;
                    this.processarDados(adesoesPartPendenteAprovacao);
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                });
        }
    }

    processarDados(adesoesPartPendenteAprovacao: AdesaoParticipantePendenteAprovacaoResponse[]) {
        adesoesPartPendenteAprovacao.forEach(x => {
            x.participantesPendentesAprovacao.forEach(y => {
                const adePermissao = {
                    nomeParticipante: x.nomeParticipante,
                    cpfParticipante: x.cpfParticipante,
                    idAdesao: x.idAdesao,
                    idTipoAdesao: x.idTipoAdesao,
                    idPlano: x.idPlano,
                    cpf: y.cpf,
                    dataNascimento: new Date(y.dataNascimento).toLocaleDateString('pt-BR'),
                    nome: y.nome,
                    dataInscricao: new Date(y.dataInscricao).toLocaleDateString('pt-BR'),
                    aprovado: false
                };
                this.adesoesPendenteAprovacaoPermissao.push(adePermissao);
            });

            x.participantesDescontoAprovado.forEach(y => {
                const adePermissao = {
                    nomeParticipante: x.nomeParticipante,
                    cpfParticipante: x.cpfParticipante,
                    idAdesao: x.idAdesao,
                    idTipoAdesao: x.idTipoAdesao,
                    idPlano: x.idPlano,
                    cpf: y.cpf,
                    dataNascimento: new Date(y.dataNascimento).toLocaleDateString('pt-BR'),
                    nome: y.nome,
                    dataInscricao: new Date(y.dataInscricao).toLocaleDateString('pt-BR'),
                    aprovado: true
                };
                this.adesoesPendenteAprovacaoPermissao.push(adePermissao);
            });
        });

        this.existeAprovacaoPendente = this.adesoesPendenteAprovacaoPermissao.length > 0;
    }

    getMockData(): AdesaoParticipantePendenteAprovacaoResponse[] {
        return [
            {
                nomeParticipante: 'Fulano da Silva',
                cpfParticipante: '11122233344',
                idAdesao: 1,
                idTipoAdesao: 2,
                idPlano: 3,
                participantesPendentesAprovacao: [
                    {
                        cpf: '11122233344',
                        dataNascimento: new Date(1990, 6, 20),
                        nome: 'Fulano da Silva',
                        dataInscricao: new Date(2023, 8, 1),
                        aprovado: false,
                    }
                ],
                participantesDescontoAprovado: []
            },
            {
                nomeParticipante: 'Ciclana Pereira',
                cpfParticipante: '55566677788',
                idAdesao: 2,
                idTipoAdesao: 3,
                idPlano: 4,
                participantesPendentesAprovacao: [],
                participantesDescontoAprovado: [
                    {
                        cpf: '55566677788',
                        dataNascimento: new Date(1985, 11, 15),
                        nome: 'Ciclana Pereira',
                        dataInscricao: new Date(2023, 8, 2),
                        aprovado: false,
                    }
                ]
            }
        ];
    }

    aprovarDescontoFolha(aprovacao: any) {
        const aprovacaoDescFolha: AprovacaoDescontoFolhaRequest = {
            cpf: aprovacao.cpf,
            idAdesao: aprovacao.idAdesao
        };
        this.isLoading = true;
        this.solicitacaoDescontoFolhaService.aprovarDescontoFolha(aprovacaoDescFolha)
            .subscribe(aprovacaoDesconto => {
                this.isLoading = false;
                this.alertModalComponent.show(aprovacaoDesconto.mensagem, aprovacaoDesconto.aprovado);
                this.ngOnInit();
            }, error => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });
    }

    desaprovarDescontoFolha(aprovacao: any) {
        const aprovacaoDescFolha: AprovacaoDescontoFolhaRequest = {
            cpf: aprovacao.cpf,
            idAdesao: aprovacao.idAdesao
        };
        this.isLoading = true;
        this.solicitacaoDescontoFolhaService.desaprovarDescontoFolha(aprovacaoDescFolha)
            .subscribe(aprovacaoDesconto => {
                this.isLoading = false;
                this.alertModalComponent.show(aprovacaoDesconto.mensagem, aprovacaoDesconto.aprovado);
                this.ngOnInit();
            }, error => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });
    }
}
