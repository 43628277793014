import { DadosAtaColegiado } from '../models/dados-ata-colegiado.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Service } from './service';

import { TipoSeDocumento } from '../constants/tipo-se-docs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ArquivosService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.API_DOCUMENTOS;
    }

    getArquivoByNome(nomeArquivo: string, callback?: Function, thisArg?: any) {
        const url = `${this.url}/api/Download/GetGeral?nome=${nomeArquivo}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    getArquivoSeguro(callback?: Function, thisArg?: any) {
        const url = `${this.url}/api/Download/GetSeguroVida`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    getArquivoSeguroVida(regFibra: string, callback?: Function, thisArg?: any) {
        const url = `${this.url}/api/Download/GetArquivoSeguroVida/${regFibra}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    getArquivoInforme(callback?: Function, thisArg?: any) {
        const url = `${this.url}/api/Download/GetInformeRendimento`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    getAtaColegiado(tipoAta: string, callback?: Function, thisArg?: any) {
        const url = `${this.url}/api/Download/GetAtaColegiado?tipoata=${tipoAta}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    getDadosAtaColegiado(tipoAta: string): Observable<any> {
        const url = `${this.url}/api/Download/GetDadosAtaColegiado?tipoata=${tipoAta}`;
        return this.get<DadosAtaColegiado>(url);
    }

    getSeDocs(tipoSeDoc: TipoSeDocumento, callback?: Function, thisArg?: any, erroCallBack?: Function) {

        const url = `${this.url}/api/Download/GetSeDocs?tipoNormativo=${tipoSeDoc}`;
        this.baixarArquivo(url, 'application/pdf', callback, erroCallBack, thisArg);
    }

    getSeDocsByName(idDoc: string ,fileName: string, callback?: Function, thisArg?: any) {
        fileName = encodeURIComponent(fileName);
        const url = `${this.url}/api/Download/GetSeDocsByName?idDoc=${idDoc}&fileName=${fileName}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    getListSeDocs(tipoSeDoc: TipoSeDocumento): Observable<any> {
        const url = `${this.url}/api/Download/GetListSeDocs?tipoNormativo=${tipoSeDoc}`;
        return this.get<string[]>(url);
    }

    getIndiceFinanceiro(cpf: string, checkIfExists: boolean, callback?: Function, erroCallBack?: Function, thisArg?: any) {
        const url = `${this.url}/api/Download/GetIndiceReajusteSE?cpfUsuario=${cpf}&checkIfExists=${checkIfExists}`;

        if(checkIfExists){
           return this.get<any>(url);
        }

        this.baixarArquivo(url, 'application/pdf', callback, erroCallBack, thisArg);
    }
}
