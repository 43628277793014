import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';

import { SideMenuConfig } from './side-menu-config';
import { SideMenuCdConfig } from './side-menu-cd-config';
import { SideMenuItem } from '../../models/side-menu-item.model';
import { removerAcentos } from '../../functions/remover-acentos';
import { ArquivosService } from '../../services/arquivos.service';
import { AuthService } from '../../services/auth.service';
import { TermoAdesaoService } from '../../services/termo-adesao.service';
import { GestaoFinanceiraEmprestimoService } from '../../services/gestao-financeira-emprestimo.service';
import { Contrato } from '../../domain/gestaofinanceira/emprestimo/models';
import { TipoSeDocumento } from '../../constants/tipo-se-docs';
import { AdesaoService } from '../../services/adesao.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ESituacaoParticipante } from '../../enums';
import { InformacoesAdicionais, ResumoAdesaoDto } from '../../models/sinqia-api';

declare const jQuery: any;

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit, AfterViewInit {
    @Input() configMenu: String;
    @ViewChild('accordion', { static: true }) el: ElementRef;
    @ViewChild('navbarToggler', { static: true }) navbarToggler: ElementRef;
    existeBloqueio: boolean;
    baixandoArquivo = false;
    config = SideMenuConfig;
    exceptEmprestimo = true;
    nomeArquivoASerBaixado: string;
    onlyEmprestimo = true;
    situacaoParticipante: string | undefined = undefined;
    contrato: Contrato;
    status: boolean;
    adesao: ResumoAdesaoDto | null;
    informacoesAdicionais: InformacoesAdicionais;
    nomeSituacaoParticipante: string | undefined;

    constructor(
        private arquivosService: ArquivosService,
        private termoAdesaoService: TermoAdesaoService,
        private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
        private adesaoService: AdesaoService,
        private sessionStorage: SessionStorageService
    ) {
    }

    ngOnInit() {
        switch (this.configMenu) {
            case "cd":
                this.config = SideMenuCdConfig;
                break;
            default:
                this.config = SideMenuConfig;
                break;
        }
        const lFound = this.config.find(l => l.RouterLink === '/termo-adesao');
        this.termoAdesaoService.isTermAccepted(false)
            .subscribe(isAccepted => {
                if (!isAccepted) {
                    if (!lFound) {
                        this.config.push({
                            Name: 'Termo de Adesão',
                            RouterLink: '/termo-adesao'
                        });
                    }
                } else {
                    this.config = this.config.filter(l => l.RouterLink !== '/termo-adesao');
                }
            });

        this.adesao = this.adesaoService.getAdesaoContexto();

        this.situacaoParticipante = this.adesao?.tipoSituacao;

        this.nomeSituacaoParticipante = this.adesao?.situacaoParticipante;

        this.informacoesAdicionais = this.adesaoService.getInformacoesAdicionais();

        console.log(this.informacoesAdicionais)
        const isSimulado = this.sessionStorage.get('isSimulado');
        const role = this.sessionStorage.get('roleSimulado');

        if (isSimulado === 'true') {
            if (role === 'auth_simulado_menosemprestimo') {
                this.onlyEmprestimo = false;
            } else if (role === 'auth_simulado_emprestimo') {
                this.exceptEmprestimo = false;
            }
        }

        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetContratoAtivo(true).subscribe(contrato => {
            if (contrato) {
                this.contrato = contrato;
            }
        });
    }

    ngAfterViewInit() {
        jQuery(this.el.nativeElement).on('hide.bs.collapse', (e: Event) => {
            jQuery(e.target).find('.collapse.show').collapse('hide');
        });
    }

    downloadFile(nomeArquivo: string, nomeSecao: string) {
        this.baixandoArquivo = true;
        this.nomeArquivoASerBaixado = nomeSecao;
        this.arquivosService.getArquivoByNome(nomeArquivo, () => {
            this.baixandoArquivo = false;
        }, this);
    }

    downloadSeDocs(tipoSeDoc: TipoSeDocumento, nomeSecao: string) {
        this.baixandoArquivo = true;
        this.nomeArquivoASerBaixado = nomeSecao;
        this.arquivosService.getSeDocs(tipoSeDoc, () => {
            this.baixandoArquivo = false;
        }, this);
    }

    downloadSeDocsByName(tipoSeDoc: string, nomeDoc: string) {
        this.baixandoArquivo = true;
        this.nomeArquivoASerBaixado = nomeDoc;
        this.arquivosService.getSeDocsByName(tipoSeDoc,this.nomeArquivoASerBaixado, () => {
            this.baixandoArquivo = false;
        }, this);
    }

    getId(item: SideMenuItem) {
        var sanitizedItemName = "";
        if (item.Id != undefined) {
            var sanitizedItemName = item.Id;
        } else {
            var itemNameWithoutSpaces = removerAcentos(item.Name.replace(/\s/g, '-').toLocaleLowerCase());
            var sanitizedItemName = String(itemNameWithoutSpaces);
        }
        return `app-sidemenu-ul-${sanitizedItemName}`;
    }

    isAuthorizedToAccess(item: SideMenuItem) {
        if (!item.Authorization) {
            return true;
        }

        if(item.RouterLink == "solicitacoes/prorrogacao-adiantamento-parcial"){
            let situacaoParticipante: ESituacaoParticipante | null = null;

            if (typeof item.Authorization[0] == 'number') {
                situacaoParticipante = item.Authorization[0] as ESituacaoParticipante;
            }

            let autorizado = false;
          
            autorizado = this.adesaoService.validarSituacaoPensionistaOuAssistido(situacaoParticipante, this.situacaoParticipante);

            return autorizado;
        }
        
        if (item.Authorization[0] === 'mostrar_acompanhamento' ||
            item.Authorization[0] === 'mostrar_pagamentos') {
            if (this.contrato) {
                return true;
            } else {
                return false;
            }
        }

        if(item.Authorization[0] === 'auth_usuario_autopatrocinado'){
            if(this.nomeSituacaoParticipante == "Bpd" 
                || this.nomeSituacaoParticipante == "Autopatrocinado"
                || (this.nomeSituacaoParticipante == "Falecido Com Pensionista" && this.sessionStorage.get('tipoConcessao') == "B.e.t.m.")
                || (this.sessionStorage.get('tipoConcessao') == "Pensão por Morte" && this.sessionStorage.get('isBeneficioTemporarioAte21YO') == "true")
            ){
                return false;
            }else{
                return true;
            }
        }

        if (item.Authorization[0] === 'mostrar_baixar_app') {
            return false;
        }

        if (item.Authorization[0] == AuthService.EXISTE_PENDENCIA_APROVACAO) {
            let existePendencia = this.sessionStorage.get('existePendenciaAprovacao');
            if (existePendencia) {
                let existePendenciaAprovacao = JSON.parse(existePendencia.toLocaleLowerCase()) as boolean;
                return existePendenciaAprovacao;
            }
        }

        let situacaoParticipante: ESituacaoParticipante | null = null;
        if (typeof item.Authorization[0] == 'number') {
            situacaoParticipante = item.Authorization[0] as ESituacaoParticipante;
        }

        let autorizado = false;
        if (situacaoParticipante) {
            ;
            switch(situacaoParticipante)
            {
                case ESituacaoParticipante.EmpregadoFibra:
                    autorizado = this.informacoesAdicionais.empregadoFibra;
                    break;
                default:
                    autorizado = this.adesaoService.validarSituacao(situacaoParticipante, this.situacaoParticipante);
            }

        }

        return autorizado;
    }

    openExternalURL(link: string) {
        if (this.sessionStorage.get('accessTokenIntegracao') && link.indexOf('token') > 0) {
            link = link + this.sessionStorage.get('accessTokenIntegracao').replace('Bearer ', '');
        }

        window.open(link, 'blank');
    }

    collapseNav() {
        if (this.navBarTogglerIsVisible()) {
            this.navbarToggler.nativeElement.click();
        }
    }

    navBarTogglerIsVisible() {
        return this.navbarToggler.nativeElement.offsetParent !== null;
    }

    get isAndroidDevice() {
        return navigator.userAgent.match(/Android/i);
    }

    get isAppleDevice() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }
}
