export const environment = {
    production: false,
    API_LOGIN: 'http://localhost:44333',
    API_STATUSAPI: 'http://localhost:44333/api/StatusApi',
    API_GESTAO_PLANO_CADASTRO: 'http://localhost:55547',
    API_EMPRESTIMO: 'http://localhost:52347/',
    API_GESTAO_PLANO_BENEFICIO: 'http://localhost:55545',
    API_CONTRIBUICAO: 'http://localhost:55546/',
    API_GESTAO_PLANO_BI: 'http://localhost:4012',
    API_GESTAO_RELACIONAMENTO_COMUNICACAO: 'http://localhost:55561',
    API_DOCUMENTOS: 'http://localhost:61771',
    URL_DO_AMBIENTE: 'http://localhost:4200',
    URL_AUTENTICACAOAD: 'https://hom-fibra-api-management.azure-api.net/hom/login/GetAdUser',
    URL_API_NOTICIAS: "https://www.fundacaoitaipu.com.br/app/noticias",
    URL_API_TV_FIBRA: 'https://www.fundacaoitaipu.com.br/app/tv',
    API_LOG: 'http://localhost:55500',
    KEY_CLIENT_ID: '4ce66a42-d836-41e3-b88e-baf2af9d5298',
    MSAL_AUTHORITY: 'https://login.microsoftonline.com',
    KEY_TENANT_ID: '6f1486b7-7393-4f00-baf1-6718c254b2e6',
    COOKIE_DOMAIN: 'localhost'
};