export function isAndroidDevice() {
    return navigator.userAgent.match(/Android/i);
}

export function isAppleDevice() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
}

export function isString(value: any) {
    return typeof value === 'string';
}


export function validFullName(str: string): boolean {
    let numsStr = str.replace(/[^0-9]/g, '');

    if (!numsStr || numsStr == '') return true;

    return !Number.isInteger(Number(numsStr));
}

export function validCPF(cpf: string) {
    if (cpf == null) {
        return false;
    }
    cpf.replace('.', '').trim();
    cpf.replace('-', '');
    if (cpf.length != 11) {
        return false;
    }
    if ((cpf == '00000000000') ||
        (cpf == '11111111111') ||
        (cpf == '22222222222') ||
        (cpf == '33333333333') ||
        (cpf == '44444444444') ||
        (cpf == '55555555555') ||
        (cpf == '66666666666') ||
        (cpf == '77777777777') ||
        (cpf == '88888888888') ||
        (cpf == '99999999999')) {
        return false;
    }
    let numero: number = 0;
    let caracter: string = '';
    let numeros: string = '0123456789';
    let j: number = 10;
    let somatorio: number = 0;
    let resto: number = 0;
    let digito1: number = 0;
    let digito2: number = 0;
    let cpfAux: string = '';
    cpfAux = cpf.substring(0, 9);
    for (let i: number = 0; i < 9; i++) {
        caracter = cpfAux.charAt(i);
        if (numeros.search(caracter) == -1) {
            return false;
        }
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
        digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i: number = 0; i < 10; i++) {
        caracter = cpfAux.charAt(i);
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
        digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
        return false;
    }
    else {
        return true;
    }

}

export function scrollIntoViewToTop() {
    window.document.body.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
    });
}

export function obterIdade(dataNascimento: Date): number {
    let hoje = new Date();

    // Retorna a diferença entre hoje e a data de nascimento em anos.
    let idade = hoje.getFullYear() - dataNascimento.getFullYear();

    //Retorna a diferença de mês do mês de nascimento para o atual.
    var mes = hoje.getMonth() - dataNascimento.getMonth();

    //Caso ainda não tenha ultrapassado o dia e o mês
    if (mes < 0 || (mes === 0 && hoje.getDate() < dataNascimento.getDate())) {
        idade--;
    }

    return idade;
}

export function validarCep(cep: string) {
    let valido = cep != null && cep != undefined && cep != '' && cep.trim() != '';
    if (!valido)
        return false;

    const regex = /^\d{8}$/;
    const cepLimpo = limparMascaraCep(cep);
    return regex.test(cepLimpo) && parseInt(cepLimpo) > 0;
}

export function limparMascaraCep(cep: string) {
    let cepNumeros = cep?.replace(/\D/g, '');
    return cepNumeros;
}

export function obfuscateEmail(email: string, replaceValue: string = '*') {
    return email.replace(/(?<=.{3})[^@](?=.*@)|(?<=@.{3})[^.](?=.*\..{3})/g, replaceValue);
}

export function obfuscatePhoneNumber(number: string, replaceValue: string = '*') {
    return number.replace(/(?<=\d{2})\d(?=\d{4})/g, replaceValue);
}

export function applyPhoneNumberMask(phoneNumber: string): string {
    return phoneNumber.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
}

export function retornaValorEnumerador(enumObj: any, key: string): any {
    return enumObj[key as keyof typeof enumObj];
}

export function retornaChaveEnumerador(enumObj: any, value: number): string | undefined {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
}
