import { Component, OnInit, ViewChild } from '@angular/core';
import { GestaoFinanceiraEmprestimoService, TimeLineEmprestimo, TermoAdesaoService, Contrato, ETipoEnvioNotificacao, SignDataModel } from '@fibra/fibra-shared-lib';
import { SignWidgetComponent } from 'projects/FibraAutoAtendimento/src/app/components/clicksign/sign-widget/sign-widget.component';
import { NotificationOptionDialogComponent } from 'projects/FibraAutoAtendimento/src/app/components/notification-option-dialog/notification-option-dialog.component';

@Component({
    selector: 'app-time-line',
    templateUrl: './time-line.component.html',
    styleUrls: ['./time-line.component.css']
})
export class TimeLineComponent implements OnInit {

    @ViewChild('signWidget') signWidget: SignWidgetComponent
    @ViewChild('notificationOptionDialog') notificationOptionDialog: NotificationOptionDialogComponent;
    mostrarSignWidget: boolean = false;
    headerDialogNotification: string = 'Verificação de Segurança';
    descricaoNotificacaoEmail: string = 'Receber um código no e-mail cadastrado';
    descricaoNotificacaoSms: string = 'Receber um código através de SMS no número cadastrado';
    notificacaoInformacaoExtra: string = 'Caso seu endereço de e-mail ou número de telefone esteja incorreto, realize a atualização cadastral.'

    isLoading = true;
    possuiEmprestimoPendente = false;
    confirmarToken = false;
    reloadAposReenvioToken = false;
    timeLineEmprestimo: TimeLineEmprestimo;
    contrato: Contrato;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
        private termoAdesaoService: TermoAdesaoService) { }

    ngOnInit() {
        this.mostrarTimeLine();
    }

    mostrarTimeLine() {
        this.termoAdesaoService.isTermAccepted(true).subscribe(termoAceito => {
            if (termoAceito) {
                this.gestaoFinanceiraEmprestimoService.Emprestimo_GetHistoricoSituacaoContratoPendente().subscribe(data => {
                    this.timeLineEmprestimo = data;
                    this.possuiEmprestimoPendente = this.timeLineEmprestimo && termoAceito ? true : false;
                    this.isLoading = false;
                });
            }
        });
    }

    baixarContrato() {
        this.isLoading = true;
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetContratoSESuite
            (this.timeLineEmprestimo.matriculaParticipante, this.timeLineEmprestimo.id, () => { this.isLoading = false; }, this);
    }

    enviarNovoCodigo() {
        this.notificationOptionDialog.show();
    }

    exibirModalTokenChange(exibirModalToken: boolean) {
        this.isLoading = exibirModalToken;
        this.confirmarToken = false;
    }

    depoisDeConfirmarToken(resultado: boolean) {
        this.isLoading = true;
        this.confirmarToken = false;
        this.ngOnInit();
    }

    selecionarTipoEnvioNotificacao(eTipoEnvioNotificacao: ETipoEnvioNotificacao) {
        this.isLoading = true;
        this.notificationOptionDialog.close();
        this.gestaoFinanceiraEmprestimoService.Emprestimo_FinalizarSolicitacaoPendente(this.timeLineEmprestimo.id, eTipoEnvioNotificacao)
            .subscribe((data:any) => {
                this.mostrarSignWidget = true;
                setTimeout(() => {
                    this.signWidget.carregarWidget({
                        signKey: data.idDaRequisicaoDaAssinatura,
                        idDocumento: data.idDoDocumento
                    });
                }, 1000);
                this.isLoading = false;
            });
    }

    async contratoAssinado(signData: SignDataModel) {        
        let $this = this;
        $this.mostrarSignWidget = false;
        try{
            var o = await this.gestaoFinanceiraEmprestimoService.EmprestimoAssinado(this.timeLineEmprestimo.id, signData.idDocumento).toPromise();

            window.location.reload();
        } catch(e) {
            console.log(e);
        }
    }
}
