import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from "./auth.service";
import { SessionInitService } from './session-init.service';
import { BasicTokenData, GraphMemberOf, GraphUserProfile, UserAd } from '../models';
import { STORAGE_KEYS } from '../constants';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from './session-storage.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_ENDPOINT_MEMBER = 'https://graph.microsoft.com/v1.0/me/memberOf';

@Injectable()
export class LoginIntegradoService {
    loginUrl: any;

    constructor(@Inject('environment') private environment,
        private authService: AuthService,
        private sessionInitService: SessionInitService,
        private route: ActivatedRoute,
        private msalService: MsalService,
        private httpClient: HttpClient,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {
        this.loginUrl = this.environment.API_LOGIN;
    }

    validateURL() {
        if (!this.route.snapshot.paramMap.has('cpf')) {
            this.authService.logout();
        }
    }

    login(cpf: string, regFibra: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.httpClient.get<GraphUserProfile>(GRAPH_ENDPOINT)
                .toPromise().then(profile => {
                    if (profile) {
                        return this.getMember(profile, cpf, regFibra);
                    }
                    reject(false);
                }, () => {
                    reject(false);
                });
        });
    }

    getMember(profile: GraphUserProfile, cpf: string, regFibra: string) {
        return new Promise<boolean>((resolve, reject) => {

            this.httpClient.get<any>(GRAPH_ENDPOINT_MEMBER)
                .toPromise().then(member => {
                    let memberValue = member.value;
                    const user = this.userAdBuilder(profile, memberValue, cpf, regFibra);

                    this.loginAd(user).subscribe(data => {
                        resolve(true);
                        console.log('doLoginAd.data: ', data);
                    }, error => {
                        reject(false);
                    });
                }, () => {
                    reject(false);
                });
        });
    }

    userAdBuilder(profile: GraphUserProfile, members: GraphMemberOf[], cpf: string, regFibra: string): UserAd {
        let userAd = new UserAd();
        userAd.areas = [];
        userAd.regFibra = regFibra;
        userAd.email = profile.mail;
        userAd.name = profile.displayName;
        userAd.cpf = cpf;

        members.forEach(data => {
            userAd.areas.push(data.onPremisesSamAccountName);
        });

        userAd.areas = userAd.areas.filter(d => d !== null && d !== undefined);

        const brokenName = profile.userPrincipalName.split('@');
        members.forEach(data => {
            if (data.onPremisesNetBiosName && data.onPremisesNetBiosName !== '') {
                userAd.user = data.onPremisesNetBiosName + '\\' + brokenName[0];
                return userAd;
            }
        });
        return userAd;
    }

    loginAd(userAd: UserAd): Observable<any> {
        const url = `${this.loginUrl}/api/Account/GetAdUser`;
        return this.httpClient.post<any>(url, userAd)
            .pipe(tap(data => {
                if (!JSON.parse(data.token).access_token) {
                    this.logout();
                } else {
                    let tokenParams = JSON.parse(data.token);
                    const tokenData: BasicTokenData = {
                        access_token: tokenParams.access_token,
                        expires: tokenParams.expires,
                        existePendenciaAprovacao: true,
                        tipoConcessao: tokenParams.tipoConcessao
                    };

                    if (tokenParams.access_token) {
                        if (data.role !== '') {
                            this.sessionStorageService.set(STORAGE_KEYS.LOGIN_INTEGRADO, 'true');
                            this.sessionStorageService.set(STORAGE_KEYS.LOGIN_INTEGRADO_ROLE, data.role);
                            this.initSession(tokenData);
                        }
                    } else {
                        this.logout();
                    }
                }
            }));
    }


    private initSession(tokenData: BasicTokenData) {
        this.sessionInitService.sessionInit(tokenData).then(success => {
            if (success) {
                this.router.navigate(['/painel']);
            }
        }).catch(error => {
            console.error(error);
        });;
    }

    logout() {
        this.msalService.logout();
    }

    isLoginIntegdado() {
        return this.sessionStorageService.get(STORAGE_KEYS.LOGIN_INTEGRADO) == 'true';
    }
}
